.app-popup {
    min-width: 50rem;
}

.popup-large .popup-content {
    min-width: 40rem;
    max-width: 60rem;
}

.thumbnail {
    width: 3rem;
    height: 3rem;
    border-radius: 4px;
}

.action-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.action-item {
    list-style: none;
    display: inline-block;
    margin-right: 0.5rem;
}

.app-config-section {
    background: #13202d;
    padding: 1rem;
    border-radius: 6px;
}

.app-config-section:not(:first-child) {
    margin-top: 0.5rem;
}

.app-config-section-add a {
    width: 100%;
    padding: 1rem;
}

.section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-title {
    font-weight: 300 !important;
    font-size: 16px;
    flex-grow: 1;
}